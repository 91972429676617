// import React, { useState, useEffect } from 'react';
// import { ReactMic } from 'react-mic';
// import axios from 'axios';

// const AudioRecorder = ({ onTranscript }) => {
//     const [record, setRecord] = useState(false);
//     const [showPlanOptions, setShowPlanOptions] = useState(false);
//     const [localTranscript, setLocalTranscript] = useState('');
//     const [loading, setLoading] = useState(false);
//     const [timer, setTimer] = useState(0);
//     const [intervalId, setIntervalId] = useState(null);
//     const [finalRecordingLength, setFinalRecordingLength] = useState(0);

//     const startRecording = () => {
//         const audioContext = new (window.AudioContext || window.webkitAudioContext)();
//         audioContext.resume().then(() => {
//             setRecord(true);
//             setLoading(true);
//             setShowPlanOptions(false);

//             setTimer(0);
//             const id = setInterval(() => {
//                 setTimer(prevTimer => prevTimer + 1);
//             }, 1000);
//             setIntervalId(id);
//         });
//     };

//     const stopRecording = () => {
//         setRecord(false);
//         setShowPlanOptions(true);

//         clearInterval(intervalId);
//         setIntervalId(null);

//         setFinalRecordingLength(timer);
//     };

//     const onStop = async (recordedBlob) => {
//         setLoading(true);
//         const formData = new FormData();
//         formData.append('audio', recordedBlob.blob);

//         try {
//             const response = await axios.post('http://localhost:5000/transcribe', formData, {
//                 headers: { 'Content-Type': 'multipart/form-data' }
//             });

//             const transcriptText = response.data.text;
//             setLocalTranscript(transcriptText);
//             onTranscript(transcriptText);
//         } catch (error) {
//             console.error('Error during fetch:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     return (
//         <div>
//             <ReactMic
//                 record={record}
//                 onStop={onStop}
//                 strokeColor="#000000"
//                 backgroundColor="#FF4081" />
//             <button onClick={startRecording} type="button" disabled={record}>Start</button>
//             <button onClick={stopRecording} type="button" disabled={!record}>Stop</button>

//             {loading && (
//                 <div className="spinner-container-inline">
//                     <div className="spinner-inline"></div>
//                     <p>Recording in Progress... {Math.floor(timer / 60)} minutes {String(timer % 60).padStart(2, '0')} seconds</p>
//                 </div>
//             )}

//             {!loading && finalRecordingLength > 0 && (
//                 <div>
//                     <p>Total Recording Length: {Math.floor(finalRecordingLength / 60)} minutes {String(finalRecordingLength % 60).padStart(2, '0')} seconds</p>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default AudioRecorder;


import React, { useState } from 'react';
import { ReactMic } from 'react-mic';
import axios from 'axios';

const AudioRecorder = ({ onTranscript }) => {
    const [record, setRecord] = useState(false);
    const [showPlanOptions, setShowPlanOptions] = useState(false);
    const [localTranscript, setLocalTranscript] = useState('');
    const [loading, setLoading] = useState(false);
    const [timer, setTimer] = useState(0);
    const [intervalId, setIntervalId] = useState(null);
    const [finalRecordingLength, setFinalRecordingLength] = useState(0);

    const startRecording = () => {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        audioContext.resume().then(() => {
            setRecord(true);
            setLoading(true);
            setShowPlanOptions(false);
            setTimer(0);
            const id = setInterval(() => {
                setTimer((prevTimer) => prevTimer + 1);
            }, 1000);
            setIntervalId(id);
        });
    };

    const stopRecording = () => {
        setRecord(false);
        setShowPlanOptions(true);
        clearInterval(intervalId);
        setIntervalId(null);
        setFinalRecordingLength(timer);
    };

    const onStop = async (recordedBlob) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('audio', recordedBlob.blob);

        try {
            console.log("Sending request to /transcribe endpoint...");
            const response = await axios.post('https://rehabrcm.ai/transcribe', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            const transcriptText = response.data.text;
            setLocalTranscript(transcriptText);
            console.log("Transcription Response: ", transcriptText);
            onTranscript(transcriptText);
        } catch (error) {
            console.error('Error during fetch:', error);
            if (error.response) {
                console.error('Error Response Data: ', error.response.data);
                console.error('Error Response Status: ', error.response.status);
                console.error('Error Response Headers: ', error.response.headers);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <ReactMic record={record} onStop={onStop} strokeColor="#000000" backgroundColor="#FF4081" />
            <button onClick={startRecording} type="button" disabled={record}>
                Start
            </button>
            <button onClick={stopRecording} type="button" disabled={!record}>
                Stop
            </button>

            {loading && (
                <div className="spinner-container-inline">
                    <div className="spinner-inline"></div>
                    <p>
                        Recording in Progress... {Math.floor(timer / 60)} minutes {String(timer % 60).padStart(2, '0')} seconds
                    </p>
                </div>
            )}

            {!loading && finalRecordingLength > 0 && (
                <div>
                    <p>
                        Total Recording Length: {Math.floor(finalRecordingLength / 60)} minutes {String(finalRecordingLength % 60).padStart(2, '0')} seconds
                    </p>
                </div>
            )}
        </div>
    );
};

export default AudioRecorder;














