import React, { useState, useRef } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const TherapyNoteForm = ({ data, transcript, onSubmitPlan, selectedPlan, setSelectedPlan }) => {
    const [editedTranscript, setEditedTranscript] = useState(transcript); // Allow editing of the transcript
    const formRef = useRef(null);
    const [fileType, setFileType] = useState('pdf');

    const handleFileTypeChange = (event) => {
        setFileType(event.target.value);
    };

    const handleDownloadForm = async () => {
        const element = formRef.current;
        if (fileType === 'pdf') {
            const canvas = await html2canvas(element);
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            pdf.addImage(imgData, 'PNG', 0, 0);
            pdf.save('therapy_note.pdf');
        } else if (fileType === 'png') {
            const canvas = await html2canvas(element);
            const imgData = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = imgData;
            link.download = 'therapy_note.png';
            link.click();
        }
    };

    const renderForm = () => {
        if (!data) {
            // When no data is available, show transcript editing and plan selection
            return (
                <div>
                    <h3>Transcript Preview</h3>
                    <textarea
                        value={editedTranscript}
                        onChange={(e) => setEditedTranscript(e.target.value)} // Handle editing
                        rows="10"
                        cols="50"
                    />
                    <div>
                        <label>Select Plan:</label>
                        <select id="planType" value={selectedPlan} onChange={(e) => setSelectedPlan(e.target.value)}>
                            <option value="A">Kaiser Permanente</option>
                            <option value="B">Highmark</option>
                            <option value="C">Bluecross Blue Shield</option>
                        </select>
                        <button onClick={() => onSubmitPlan(selectedPlan, editedTranscript)}> {/* Pass the edited transcript */}
                            Generate Form
                        </button>
                    </div>
                </div>
            );
        }

        // Render the form when data is available
        switch (data.plan_type) {
            case 'A':
                return (
                    <div ref={formRef} style={{ border: '2px solid blue', padding: '20px', borderRadius: '8px' }}>
                        <h2 style={{ color: 'blue' }}>Kaiser Permanente</h2>
                        <p><strong>Name:</strong> {data.extracted_data.patientName}</p>
                        <p><strong>Date Of Birth:</strong> {data.extracted_data.dob}</p>
                        <p><strong>Clinical Number:</strong> {data.extracted_data.clinicalNumber}</p>
                        <p><strong>Treatment</strong> {data.extracted_data.treatment}</p>
                    </div>
                );
                case 'B':
                    return (
                        <div ref={formRef} style={{ border: '2px solid blue', padding: '20px', borderRadius: '8px' }}>
                            <h2 style={{ color: 'red' }}>Kaiser Permanente</h2>
                            <p><strong>Name:</strong> {data.extracted_data.patientName}</p>
                            <p><strong>Date Of Birth:</strong> {data.extracted_data.dob}</p>
                            <p><strong>Clinical Number:</strong> {data.extracted_data.clinicalNumber}</p>
                            <p><strong>Notes:</strong> {data.extracted_data.signature}</p>
                        </div>
                    );
            // Add cases for 'B' and 'C'
            default:
                return <p>Invalid plan type selected.</p>;
        }
    };

    return (
        <div className="therapy-note">
            {renderForm()}
            {data && (
                <div className="transcript-actions">
                    <label htmlFor="fileType">Select File Type:</label>
                    <select id="fileType" value={fileType} onChange={handleFileTypeChange}>
                        <option value="pdf">PDF (.pdf)</option>
                        <option value="png">Image (.png)</option>
                    </select>
                    <button onClick={handleDownloadForm}>Download Form</button>
                </div>
            )}
        </div>
    );
};

export default TherapyNoteForm;







