// import React, { useState } from 'react';
// import axios from 'axios';
// import './App.css';
// import TherapyNoteForm from './components/TherapyNoteForm';
// import AudioRecorder from './components/AudioRecorder';

// const App = () => {
//   const [transcript, setTranscript] = useState('');
//   const [therapyNoteData, setTherapyNoteData] = useState(null);
//   const [isTranscriptReady, setIsTranscriptReady] = useState(false);
//   const [selectedPlan, setSelectedPlan] = useState('A');

//   const handleTranscript = (transcriptText) => {
//     setTranscript(transcriptText);
//     setIsTranscriptReady(true); // Show the transcript after stopping the recording
//     setTherapyNoteData(null); // Clear any existing form data
//   };

//   const handleSubmitPlan = async (planType, editedTranscript) => {
//     try {
//       const parseResponse = await axios.post('http://localhost:5000/parse', {
//         transcript: editedTranscript, // Use the edited transcript
//         plan_type: planType
//       });

//       const therapyNoteData = parseResponse.data;
//       setTherapyNoteData(therapyNoteData);
//       setIsTranscriptReady(false); // Hide transcript once the form is generated
//     } catch (error) {
//       console.error('Error during fetch:', error);
//     }
//   };

//   return (
//     <div className="container">
//       <h1>RehabRcm</h1>
//       <AudioRecorder onTranscript={handleTranscript} />

//       {isTranscriptReady && !therapyNoteData && (
//         <TherapyNoteForm
//           transcript={transcript}
//           onSubmitPlan={handleSubmitPlan}
//           selectedPlan={selectedPlan}          
//           setSelectedPlan={setSelectedPlan}    
//         />
//       )}

//       {therapyNoteData && (
//         <TherapyNoteForm
//           data={therapyNoteData}
//           selectedPlan={selectedPlan}
//           setSelectedPlan={setSelectedPlan}
//         />
//       )}
//     </div>
//   );
// };

// export default App;

import React, { useState } from 'react';
import axios from 'axios';
import './App.css';
import TherapyNoteForm from './components/TherapyNoteForm';
import AudioRecorder from './components/AudioRecorder';

const App = () => {
  const [transcript, setTranscript] = useState('');
  const [therapyNoteData, setTherapyNoteData] = useState(null);
  const [isTranscriptReady, setIsTranscriptReady] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('A');

  const handleTranscript = (transcriptText) => {
    setTranscript(transcriptText);
    setIsTranscriptReady(true); // Show the transcript after stopping the recording
    setTherapyNoteData(null); // Clear any existing form data
  };

  const handleSubmitPlan = async (planType, editedTranscript) => {
    try {
      // Use HTTPS to connect to the backend on the secure domain
      const parseResponse = await axios.post('https://rehabrcm.ai/parse', {
        transcript: editedTranscript, // Use the edited transcript
        plan_type: planType
      });

      const therapyNoteData = parseResponse.data;
      setTherapyNoteData(therapyNoteData);
      setIsTranscriptReady(false); // Hide transcript once the form is generated
    } catch (error) {
      console.error('Error during fetch:', error);
    }
  };

  return (
    <div className="container">
      <h1>RehabRcm</h1>
      <AudioRecorder onTranscript={handleTranscript} />

      {isTranscriptReady && !therapyNoteData && (
        <TherapyNoteForm
          transcript={transcript}
          onSubmitPlan={handleSubmitPlan}
          selectedPlan={selectedPlan}          
          setSelectedPlan={setSelectedPlan}    
        />
      )}

      {therapyNoteData && (
        <TherapyNoteForm
          data={therapyNoteData}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
        />
      )}
    </div>
  );
};

export default App;













